import React from 'react';
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"

import './Language.css';
import swedenFlag from './img/sweden.png';
import ukFlag from './img/uk.png';

export class Language extends React.Component {

    render() {
        let className = "language";
        return (
            <I18n>
                {({ i18n }) => (
                    <div lang={this.props.lang}
                        aria-label={i18n._(t`language.switcher.a11y`)}
                        className={className}
                        role="radiogroup"
                        tabIndex={0}>
                        <div aria-checked={this.props.lang === "en"}
                            aria-label={i18n._(t`language.en`)}
                            lang="en"
                            role="radio"
                            tabIndex={this.props.lang === "en" ? 0 : -1}
                            onClick={(state) => this.props.onChangeLanguage("en")}>
                            <img src={ukFlag} role="presentation" alt={i18n._(t`language.en`)}></img>
                        </div>
                        <div aria-checked={this.props.lang === "sv"}
                            aria-label={i18n._(t`language.sv`)}
                            lang="sv"
                            role="radio"
                            tabIndex={this.props.lang === "sv" ? 0 : -1}
                            onClick={(state) => this.props.onChangeLanguage("sv")} >
                            <img src={swedenFlag} role="presentation" alt={i18n._(t`language.sv`)}></img>
                        </div>
                    </div>
                )
                }
            </I18n>
        )
    }
}
export default Language;