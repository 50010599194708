import React from 'react';

/*
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
*/
import './IPhone.css';

export class IPhone extends React.Component {

    render() {
        //console.log(this.props)
        let className = "iphone" + (this.props.finger ? " iphone-finger" : "");
        if (this.props.zoom === true) {
            className = className + " iphone-zoom";
        }

        let live = this.props.live ? "assertive" : "off";

        let style = {};
        if (this.props.finger && this.props.finger.top && this.props.finger.left) {
            style = { "top": this.props.finger.top + "px", "left": this.props.finger.left + "px" };
        }
        let zoomStyle = {};
        if (this.props.zoom === true) {
            zoomStyle = { "top": (this.props.finger.top - 50) + "px", "left": (this.props.finger.left + 50) + "px" };
        }
        return (
            <div className={className} aria-live={live}>
                <div className="iphone-mask">
                    <div className="iphone-finger" style={style} />
                    <div className="iphone-zoom" style={zoomStyle} />
                    <img src={this.props.src} alt={this.props.alt} />
                </div>
            </div>

        );
    }
}

export default IPhone;