import React from 'react';
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
import './Breadcrumb.css';

export class Breadcrumb extends React.Component {

    render() {
        return (
            <I18n>
                {({ i18n }) => (
                    <nav className="breadcrumb">
                        <ol aria-label={i18n._(t`breadcrumb.label.a11y`)}>
                            <li itemScope itemType="http://data-vocabulary.org/Breadcrumb">
                                <span role="button" aria-label={i18n._(t`breadcrumb.home`)} onClick={() => {
                                    console.log("About to run onButton");
                                    this.props.onStateChange("front");
                                }}>{this.props.home}</span>
                            </li>
                            <li itemScope itemType="http://data-vocabulary.org/Breadcrumb" aria-current="page">
                                <span>{this.props.current}</span>
                            </li>
                        </ol>
                    </nav >
                )}
            </I18n>

        );
    }
}

export default Breadcrumb;