import React from 'react';
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
import Breadcrumb from './Breadcrumb.js'
import Postcard from './Postcard.js'
import Subfooter from './Subfooter.js'
import './InteractiveOutdoorAdventures.css'
import reservoirImg from './img/reservoir.jpeg'
import outdoorImg from './img/outdoors.jpeg'
/* import stoneImg from './img/stone.jpeg'*/
import silhouetteImg from './img/silhouette.png'

export class InteractiveOutdoorAdventures extends React.Component {

    load(state) {
        this.props.onStateChange(state);
    }

    /*
    <Postcard className="ioa-photo3" src={stoneImg}
    altText={i18n._(t`ioa.family.imgAltText`)}
    title={i18n._(t`ioa.family.title`)}
    description={i18n._(t`ioa.family.description`)}
    orientation="landscape" />
    */

    render() {
        return (
            <I18n>
                {({ i18n }) => (
                    <section className="ioa">
                        <div>
                            <header>
                                <h1>
                                    <Trans id="ioa.header"></Trans>
                                </h1>
                                <Breadcrumb home={i18n._(t`front.title`)}
                                    current={i18n._(t`ioa.header`)}
                                    onStateChange={(state) => this.load(state)} />
                            </header>
                            <p className="ioa-tagline"><Trans id="ioa.tagline"></Trans></p>
                            <div className="ioa-examples">
                                <Postcard className="ioa-photo2" src={outdoorImg}
                                    altText={i18n._(t`ioa.destination.imgAltText`)}
                                    title={i18n._(t`ioa.destination.title`)}
                                    description={i18n._(t`ioa.destination.description`)} />
                                <Postcard className="ioa-photo1"
                                    src={reservoirImg}
                                    altText={i18n._(t`ioa.citywalk.imgAltText`)}
                                    title={i18n._(t`ioa.citywalk.title`)}
                                    description={i18n._(t`ioa.citywalk.description`)}
                                    size="big" />
                                <Postcard className="ioa-photo3" src={silhouetteImg}
                                    altText={i18n._(t`ioa.story.imgAltText`)}
                                    title={i18n._(t`ioa.story.title`)}
                                    description={i18n._(t`ioa.story.description`)} />

                            </div>

                        </div>
                        <Subfooter email="aliens@vonklopp.eu"
                            color={true}
                            onBack={() => {
                                this.load("front")
                            }} />
                    </section>
                )
                }
            </I18n>
        );
    }
}

export default InteractiveOutdoorAdventures;
