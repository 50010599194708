import React from 'react';
import { Trans } from "@lingui/macro"
/*import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
*/
import './Subfooter.css';

export class Subfooter extends React.Component {

    render() {
        let mailAddress = "mailto:" + this.props.email;
        let className = "subfooter" +
            (this.props.isRoot ? " subfooter-isroot" : "") +
            (this.props.color ? " subfooter-color" : "");
        return (
            <footer className={className}>
                <a href={mailAddress}><Trans id="subfooter.contact"></Trans></a>
                <div role="button" onClick={(state) => this.props.onBack()}><Trans id="subfooter.back"></Trans></div>
            </footer>
        );
    }
}

export default Subfooter;