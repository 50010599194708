import React from 'react';
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
import { Trans } from "@lingui/macro"
import IPhone from './IPhone.js'
import './Demo.css';

export class Demo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            end: props.data.length
        };
    }

    next() {
        if (this.state.index < (this.state.end - 1)) {
            this.setState({ index: this.state.index + 1 });
        }
    }

    previous() {
        if (this.state.index > 0) {
            this.setState({ index: this.state.index - 1 });
        }
        this.render()
    }

    image() {
        return this.props.data[this.state.index].src
    }

    finger() {
        return this.props.data[this.state.index].finger
    }

    zoom() {
        return this.props.data[this.state.index].zoom
    }

    render() {
        console.log("Rerendering", this.props.data);
        const className = "demo" + (this.props.geekNote === true ? " demo-geek" : "");
        const nextDisabled = this.state.index === (this.state.end - 1);
        const prevDisabled = this.state.index === 0;
        const stepText = "beadiful.demo.step" + this.state.index;
        //const geekNote = "beadiful.demo.geekNote" + this.state.index;

        return (
            <I18n>
                {({ i18n }) => (
                    <div className={className}>
                        <div className="demo-container">
                            <IPhone src={this.image()}
                                finger={this.finger()}
                                zoom={this.zoom()}
                                live={true}
                                aria-label={i18n._(t`beadiful.screenshot0.ariaLabel`)} />

                            <div className="demo-info">
                                <div className="demo-toolbar">
                                    <div role="button"
                                        disabled={prevDisabled}
                                        aria-disabled={prevDisabled}
                                        className="demo-previous" onClick={() => {
                                            this.previous();
                                        }}><Trans id="demo.previous">Previous</Trans></div>

                                    <div role="button"
                                        disabled={nextDisabled}
                                        aria-disabled={nextDisabled}
                                        className="demo-next" onClick={() => {
                                            this.next();
                                        }}><Trans id="demo.next">Next</Trans></div>
                                </div>
                                <div className="demo-step">
                                    <p> <Trans id={stepText}></Trans></p>
                                </div>
                                <div className="demo-geeknote">
                                    <p> <Trans id="beadiful.paragraph1"></Trans></p>
                                </div>

                            </div>
                        </div>
                    </div>
                )
                }
            </I18n>
        );
    }
}

export default Demo;