import React from 'react';
/*
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
*/
import './Photo.css';

export class Photo extends React.Component {

    render() {
        return (
            <div className="photo">
                <img src={this.props.src} alt={this.props.a1tText} />
            </div>
        );
    }
}

export default Photo;