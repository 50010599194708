import React from 'react';
/*
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
*/
import './Postcard.css'

export class Postcard extends React.Component {

    render() {
        let backgroundImg = this.props.src;
        let className = "postcard" + (this.props.size === "big" ? " postcard-big" : "")
        return (
            <div className={className}>
                <div className="postcard-photo" style={{
                    backgroundImage: "url(" + backgroundImg + ")",
                }}>
                </div>
                <div className="postcard-text">
                    <h2>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                </div>
            </div >
        );
    }
}

export default Postcard;