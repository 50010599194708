import React from 'react';
import { Trans } from "@lingui/macro"
import './Front.css';

export class Front extends React.Component {

    render() {
        return (
            <section className="front" >
                <header>
                    <h1>
                        <Trans id="front.title"></Trans>
                    </h1>
                </header>

                <div className="front-grid">
                    <div className="aliens-teaser">
                        <h2><Trans id="aliens.header"></Trans></h2>
                        <p><Trans id="aliens.description"></Trans> <span role="button" onClick={() => {
                            this.props.onButton("aliens");
                        }}><Trans id="front.readMoreLink"></Trans></span>
                        </p>
                    </div>

                    <div className="ioa-teaser" >
                        <h2><Trans id="ioa.header"></Trans></h2>
                        <p><Trans id="ioa.description"></Trans> <span role="button" onClick={() => {
                            this.props.onButton("ioa");
                        }}><Trans id="front.readMoreLink"></Trans></span>
                        </p>
                    </div>

                    <div className="beadiful-teaser" >
                        <div className="beadiful-teaser-header">
                            <div role="presentation"></div>
                            <h2><Trans id="beadiful.header"></Trans></h2>
                        </div>
                        <p><Trans id="beadiful.description"></Trans> <span role="button" onClick={() => {
                            this.props.onButton("beadiful");
                        }}><Trans id="front.readMoreLink"></Trans></span>

                        </p>
                    </div>

                    <div className="a11y-teaser" >
                        <h2><Trans id="a11y.header"></Trans></h2>
                        <p><Trans id="a11y.description"></Trans> <span role="button" onClick={() => {
                            this.props.onButton("a11y");
                        }}><Trans id="front.readMoreLink"></Trans></span>
                        </p>
                    </div>
                </div>

                <footer>
                    <a href="mailto:info@vonklopp.eu"><Trans id="front.contact"></Trans></a>
                </footer>

            </section>
        );
    }
}

export default Front;
