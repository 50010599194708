import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { I18nProvider } from '@lingui/react';
/*import App from './App-Aliens.js';*/
import App from './App.js';
import Language from './Language.js'
import catalogSv from './l10n/sv/messages.js'
import catalogEn from './l10n/en/messages.js'


const catalogs = { sv: catalogSv, en: catalogEn };

//let preferredLang = "sv";

let preferredLang =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
if (preferredLang === null || preferredLang === "") {
    preferredLang = "en";
} else if (preferredLang.length !== 2) {
    preferredLang = preferredLang.substring(0, 2);
}

if (["en", "sv"].indexOf(preferredLang) === -1) {
    preferredLang = "en";
}

function setPreferredLanguage(lang) {
    console.log("setPreferredLanguage -> " + lang);
    preferredLang = lang;
    ReactDOM.render(
        <AppWrapper></AppWrapper>, document.getElementById('root'));
}

const AppWrapper = () => (
    < I18nProvider language={preferredLang} catalogs={catalogs} >
        <App state="front" lang={preferredLang} />
        <Language lang={preferredLang} onChangeLanguage={(lang) => setPreferredLanguage(lang)} />
    </I18nProvider >
)

ReactDOM.render(
    <AppWrapper></AppWrapper>, document.getElementById('root'));
