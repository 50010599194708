import React from 'react';
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
import './A11y.css';
import Breadcrumb from './Breadcrumb.js'
import Subfooter from './Subfooter.js'

class WrappedContent extends React.Component {
    render() {
        if (this.props.lang === "sv") {
            return (
                <A11ySv></A11ySv>
            );
        } else {
            return (
                <A11yEn></A11yEn>
            );
        }
    }
}

export class A11y extends React.Component {

    load(state) {
        if (this.props.isRoot === true) {
            return;
        }
        this.props.onStateChange(state);
    }

    render() {
        return (
            <I18n>
                {({ i18n }) => (
                    <section className="a11y">
                        <div>
                            <header>
                                <h1>
                                    <Trans id="a11y.header"></Trans>
                                </h1>
                                <Breadcrumb home={i18n._(t`front.title`)}
                                    current={i18n._(t`a11y.header`)}
                                    onStateChange={(state) => this.load(state)} />
                            </header>
                        </div>
                        <div className="a11y-content">
                            <WrappedContent lang={this.props.lang}></WrappedContent>
                            <Subfooter email="info@vonklopp.eu" onBack={() => {
                                this.load("front")
                            }} isRoot={this.props.isRoot} />
                        </div>
                    </section>
                )
                }
            </I18n>
        );
    }
}

export default A11y;

class A11yEn extends React.Component {

    render() {
        return (
            <div>
                <h2>How can we help?</h2>
                <ul>
                    <li>Evaluations of existing websites and mobile apps</li>
                    <li>UX design with accessibility as a first class member of the design</li>
                    <li>Development of accessible web and mobile apps</li>
                    <li>Education and lectures</li>
                </ul>

                <p>Want to know more? Check out Ana's <a href="https://www.youtube.com/channel/UCzviePSwQn4POoCt7q7NaaQ">videos
                on smartphone accessibility</a>, and blog <a
                        href="http://someminorusabilityissues.blogspot.com">Some minor usability issues</a>.</p>

                <h2>Why do you need it?</h2>
                <p><a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32016L2102">The
                EU directive on on the accessibility of the websites and mobile
                applications of public sector bodies</a> requires public sector web apps and mobile apps to be
    accessible to people with disabilities, on the following schedule:</p>
                <ol>
                    <li>Websites published after September 23, 2019 have to be accessible. </li>
                    <li>All websites have to be accessible by September 23, 2020.</li>
                    <li>Mobile apps have to be accessible by June 23, 2021.</li>

                </ol>
                <p>The directive only affects public sector apps, but developing inclusive apps
                    is a smart thing to do regardless. If you think it only affects a small
                    number of users, think again. Color blindness affects about 1 in 12 men and 1 in 200 women,
                    a large portion of any user base.
                   Check out <a
                        href="https://www.bbc.com/news/world-44535687">World Cup 2018:
                    Why millions of fans see the football like this</a> (never mind quite a few of the players!)
                    for some perspective.
                    And, if you can see now, you will certainly have a hard time reading small
                    text as you get older.
                </p>

                <p>For people who can't see at all, there are screen readers and APIs that programmers can use
                    to make sure that their apps work with the screen reader. Smartphones come with built-in
                    screen readers and are helpful tools to users who can't see, but they depend on the
                    us UX designers and programmers on doing the right thing.  <a href="https://www.applevis.com/apps/ios/education/duolingo">A
                    long thread where users help each other work
                    around severe problems with the Duolingo app</a> provides insight into the
                    perseverance and skills needed when we haven't.
                </p>

                <p>Because we need to manage layout (text that needs to grow), use of color, timely feedback and
                    access to functionality, accessibility needs to be part of the UX and visual design as well
                    as the coding phase. It can't be left until the rest of the functionality is complete,
                    and it is not enough to run automatic testing tools - you have to test with a screen
                    reader, as well as with other accessibility settings.
                </p>
            </div >
        );
    }
}

class A11ySv extends React.Component {

    render() {
        return (
            <div>
                <h2>Hur kan vi hjälpa till?</h2>
                <ul>
                    <li>Utvärdering av existerande webbplatser och mobilappar</li>
                    <li>UX design med tillgänglighet som fullvärdig komponent i designen</li>
                    <li>Utveckling av tillgängliga web- och mobilappar</li>
                    <li>Utbildning och föreläsningar</li>
                </ul>

                <p>Vill du veta med? Kolla Anas <a href="https://www.youtube.com/channel/UCzviePSwQn4POoCt7q7NaaQ">videos
                om mobilappstillgänglighet</a>, och blogg <a
                        href="http://someminorusabilityissues.blogspot.com">Some minor usability issues</a>.</p>

                <h2>Varför behövs det?</h2>
                <p><a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32016L2102">EU direktivet
                om tillgänglighet till digital offentlig service</a> kräver att webbplatser och mobilappar ska vara
                tillgängliga till personer med funktionshinder enligt följande:</p>
                <ol>
                    <li>Webbplatser publicerade efter 23 september 2019 ska vara tillgängliga (d.v.s. alla nya webbplatser måste vara tillgängliga från början)</li>
                    <li>Alla webbplatser ska vara tillgängliga från 23 september 2020.</li>
                    <li>Mobilappar ska vara tillgängliga från 23 juni 2021.</li>

                </ol>
                <p>Lagen kräver bara detta av appar från offentlig sektor, men inklusiv design är smart
                    även för andra. Det gäller så många potentiella användare. T.ex. räknar man med att
                    1 av 12 män och en av 200 kvinnor är färgblinda, d.v.s. en ganska stor andel av
                    användarbasen. Kolla den här artikeln om hur det kan vara att titta på fotboll <a
                        href="https://www.bbc.com/news/world-44535687">World Cup 2018:
                    Why millions of fans see the football like this</a> (och givetvis ganska många spelare också)
                    för perspektiv.
                    Och om det är så att man kan se alls, så kommer det garanterat att bli svårare att läsa liten text
                    när man blir äldre.
                </p>

                <p>För människor som inte kan se alls finns skärmläsare som läser upp vad som finns på skärmen
                    och låter användaren interagera med innehållet. APIer låter programmerare skriva så att
                    skärmläsaren förstår komponenterna. Smartphones har inbyggda skärmläsare och är fantastiska
                    verktyg för användare som inte kan se, men för att appar ska fungera krävs att UX designern
                    och programmeraren vet hur det ska göra. <a href="https://www.applevis.com/apps/ios/education/duolingo">En
                    recension av appen Duolingo, med en lång kommentarstråd av användare som försöker hjälpa varandra</a> ger
                    insikt i skickligheten och envisheten om vad användare går igenom när vi inte gör rätt.
                </p>

                <p>Eftersom det är nödvändigt att hantera dynamisk layout (när textstorleken behöver växa);
                    användingen av färg; hur man ger feedback till användaren; hur man initierar
                    aktiviteter etc, behöver tillgänglighet vara en del av UX och visuell design och inte
                    bara en del av kodandet. Det går inte att lämna tillgänglighet till slutet och hoppas på det bästa.
                    Man måste testa med skärmläsare och andra hjälpmedelsinställningar.
                </p>

            </div >
        );
    }
}