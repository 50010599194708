import React from 'react';
import './App.css';
import Front from "./Front.js"
import Aliens from "./Aliens.js"
import Beadiful from "./Beadiful.js"
/*import Games from "./Games.js" */
import InteractiveOutdoorAdventures from './InteractiveOutdoorAdventures.js';
import A11y from "./A11y.js"
import { setupI18n } from "@lingui/core"
import msgsEn from './l10n/en/messages.js'
import msgsSv from './l10n/sv/messages.js'
import { t } from "@lingui/macro"

export class App extends React.Component {

    constructor(props) {
        super(props);
        //let oldState = JSON.parse(localStorage.getItem('state'))

        let state = props.state;

        this.state = {
            state: state
        };
        // scroll to the top!!!!
        //console.log("State set by constructor: ", this.props.state);
    }

    load(state) {
        if (state === this.state.state) {
            return;
        }

        this.setState({
            state: state
        });
    }

    // Actually this isn't right... It's the individual components that we need to update...
    // But this is better than what we had.
    componentDidMount = () => this.scrollToTop()

    componentDidUpdate = () => this.scrollToTop()

    scrollToTop = () => {

        let l10n = (this.props.lang === "sv") ?
            setupI18n({
                language: 'sv',
                catalogs: {
                    sv: msgsSv
                },
            }) :
            setupI18n({
                language: 'en',
                catalogs: {
                    en: msgsEn
                },
            })

        switch (this.state.state) {
            case "aliens": {
                document.title = l10n._(t`aliens.header`);
                //"Aliens in Slottsskogen";
                break;
            }
            case "beadiful": {
                document.title = l10n._(t`beadiful.header`);
                break;
            }
            case "a11y": {
                document.title = l10n._(t`a11y.header`);
                break;
            }
            case "ioa": {
                document.title = l10n._(t`ioa.header`);
                break;
            }
            default:
                document.title = l10n._(t`front.doctitle`);
        }
        window.scrollTo(0, 0);
        /*
        const { index, selected } = this.props
        if (index === selected) {
            setTimeout(() => {
                this.childDiv.current.scrollIntoView({ behavior: 'smooth' })
            }, 500)
        }*/
    }

    render() {

        switch (this.state.state) {
            case "aliens":
                return (
                    <div lang={this.props.lang} className="app">
                        <Aliens onStateChange={(state) => this.load(state)} />
                    </div>
                );
            case "ioa":
                return (
                    <div lang={this.props.lang} className="app">
                        <InteractiveOutdoorAdventures onStateChange={(state) => this.load(state)} />
                    </div>
                );
            case "a11y":
                return (
                    <div lang={this.props.lang} className="app">
                        <A11y lang={this.props.lang} onStateChange={(state) => this.load(state)} />
                    </div>
                );
            case "beadiful":
                return (
                    <div lang={this.props.lang} className="app">
                        <Beadiful lang={this.props.lang} onStateChange={(state) => this.load(state)} />
                    </div>
                );
            /*
        case "games":
            return (
                <div lang={this.props.lang} className="app">
                    <Games lang={this.props.lang} onStateChange={(state) => this.load(state)} />
                </div>
            );*/
            default: {
                return (
                    <div lang={this.props.lang} className="app">
                        <Front onButton={(state) => this.load(state)} />
                    </div>
                );
            }
        }
    }
}

export default App;