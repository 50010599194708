import React from 'react';
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
import Breadcrumb from './Breadcrumb.js'
import IPhone from './IPhone.js'
import Demo from './Demo.js'
import Subfooter from './Subfooter.js'
import './Beadiful.css'
import appStoreImg from './img/appstore_wh.svg'

export class Beadiful extends React.Component {


    constructor(props) {
        super(props);
        //let oldState = JSON.parse(localStorage.getItem('state'))

        let sv = [require(`./img/screenshot_0_sv.png`),
        require(`./img/screenshot_1_sv.png`),
        require(`./img/screenshot_2_sv.png`),
        require(`./img/screenshot_3_sv.png`),
        require(`./img/screenshot_4_sv.png`),
        require(`./img/screenshot_5_sv.png`),
        require(`./img/screenshot_6_sv.png`),
        require(`./img/screenshot_7_sv.png`),
        require(`./img/screenshot_8_sv.png`),
        require(`./img/screenshot_9_sv.png`)];

        let en = [require(`./img/screenshot_0.png`),
        require(`./img/screenshot_1.png`),
        require(`./img/screenshot_2.png`),
        require(`./img/screenshot_3.png`),
        require(`./img/screenshot_4.png`),
        require(`./img/screenshot_5.png`),
        require(`./img/screenshot_6.png`),
        require(`./img/screenshot_7.png`),
        require(`./img/screenshot_8.png`),
        require(`./img/screenshot_9.png`)];

        this.state = {
            en: [
                { src: en[0] },
                { src: en[0], finger: { left: 167, top: 22 } },
                { src: en[1] },
                { src: en[1], finger: { left: 10, top: 22 } },
                { src: en[0], finger: { left: 23, top: 22 } },
                { src: en[2] },
                { src: en[2], finger: { left: 12, top: 68 } },
                { src: en[3] },
                { src: en[3], finger: { left: 88, top: 243 }, zoom: true },
                { src: en[4] },
                { src: en[4], finger: { left: 106, top: 480 } },
                { src: en[5] },
                { src: en[5], finger: { left: 8, top: 123 } },
                { src: en[6] },
                { src: en[6], finger: { left: 152, top: 477 } },
                { src: en[7] },
                { src: en[7], finger: { left: 127, top: 477 } },
                { src: en[8] },
                { src: en[8], finger: { left: 207, top: 22 } },
                { src: en[9] }
            ],
            sv: [
                { src: sv[0] },
                { src: sv[0], finger: { left: 178, top: 22 } },
                { src: sv[1] },
                { src: sv[1], finger: { left: 10, top: 22 } },
                { src: sv[0], finger: { left: 13, top: 22 } },
                { src: sv[2] },
                { src: sv[2], finger: { left: 12, top: 171 } },
                { src: sv[3] },
                { src: sv[3], finger: { left: 88, top: 243 }, zoom: true },
                { src: sv[4] },
                { src: sv[4], finger: { left: 106, top: 480 } },
                { src: sv[5] },
                { src: sv[5], finger: { left: 8, top: 123 } },
                { src: sv[6] },
                { src: sv[6], finger: { left: 161, top: 477 } },
                { src: sv[7] },
                { src: sv[7], finger: { left: 135, top: 477 } },
                { src: sv[8] },
                { src: sv[8], finger: { left: 207, top: 22 } },
                { src: sv[9] }
            ]

        };

        // scroll to the top!!!!
        //console.log("State set by constructor: ", this.props.state);
    }

    load(state) {
        this.props.onStateChange(state);
    }

    /*
    <Postcard className="ioa-photo3" src={stoneImg}
            altText={i18n._(t`ioa.family.imgAltText`)}
            title={i18n._(t`ioa.family.title`)}
            description={i18n._(t`ioa.family.description`)}
            orientation="landscape" />
        */

    render() {
        const demoData = this.state[this.props.lang];

        /*<div className="beadiful-longdescription">
                                <p><Trans id="beadiful.paragraph1"></Trans></p>
                                <p><Trans id="beadiful.paragraph2"></Trans></p>
                                <p><Trans id="beadiful.paragraph3"></Trans></p>
                                <p><Trans id="beadiful.paragraph4"></Trans></p>
                            </div>*/
        return (
            <I18n>
                {({ i18n }) => (
                    <section className="beadiful" lang={this.props.lang}>
                        <div>
                            <header>
                                <div className="beadiful-header-leading">
                                    <h1>
                                        <Trans id="beadiful.header"></Trans>
                                    </h1>
                                    <Breadcrumb home={i18n._(t`front.title`)}
                                        current={i18n._(t`beadiful.header`)}
                                        onStateChange={(state) => this.load(state)} />
                                    <p className="beadiful-tagline"><Trans id="beadiful.tagline"></Trans></p>
                                    <div>
                                        <a xmlns="http://www.w3.org/1999/xhtml" href="https://apps.apple.com/app/id1478419584">
                                            <img src={appStoreImg} alt="Apple App Store Link to the Beadiful app" /></a>
                                    </div>
                                </div>
                                <div className="beadiful-header-trailing">
                                    <div className="beadiful-iphone-wrapper">
                                        <IPhone src={require(`./img/screenshot_6.png`)} alt={i18n._(t`beadiful.headerScreenshot.a11y`)} />
                                    </div>

                                </div>
                            </header>

                            <div className="beadiful-review">
                                <p ><Trans id="beadiful.quote"></Trans></p>
                                <a href="https://www.pappasappar.se/beadiful/" target="out"><Trans id="beadiful.review.link"></Trans></a>
                            </div>

                            <h2 className="beadiful-demoheader"><Trans id="beadiful.demo.header"></Trans></h2>

                            <Demo data={demoData} key="beadiful" />

                        </div>
                        <Subfooter email="beadiful@vonklopp.eu"
                            color={true}
                            onBack={() => {
                                this.load("front")
                            }} />
                    </section>
                )
                }
            </I18n>
        );
    }
}

export default Beadiful;
