import React from 'react';
import { Trans } from "@lingui/macro"
import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"
import './Aliens.css';
import reservoirImg from './img/reservoir.jpeg'
import Breadcrumb from './Breadcrumb.js'
import Photo from './Photo.js'
import Subfooter from './Subfooter.js'

export class Aliens extends React.Component {

    load(state) {
        if (this.props.isRoot === true) {
            return;
        }
        this.props.onStateChange(state);
    }

    render() {
        let className = "aliens" + (this.props.isRoot === true ? " aliens-isroot" : "");
        let url = "https://apps.apple.com/se/app/aliens-in-slottsskogen/id1488631909?l=" + this.props.lang;
        return (
            <I18n>
                {({ i18n }) => (
                    <section className={className}>
                        <div>
                            <header>
                                <h1>
                                    <Trans id="aliens.header"></Trans>
                                </h1>
                                <Breadcrumb home={i18n._(t`front.title`)}
                                    current={i18n._(t`aliens.header`)}
                                    onStateChange={(state) => this.load(state)} />
                            </header>

                            <div className="telegram inkblot">
                                <p>
                                    <Trans id="aliens.telegram"></Trans>
                                </p>
                            </div>

                            <div className="beta">
                                <div>
                                    <a href={url} target="other"><Trans id="aliens.contact" /></a>
                                </div>
                            </div>

                            <Photo src={reservoirImg} altText={i18n._(t`aliens.image.a11y`)} />

                            <div className="telegram location">
                                <h2><Trans id="aliens.locationHeader"></Trans></h2>
                                <p><Trans id="aliens.locationDescription"></Trans></p>
                            </div>

                            <div className="telegram telegram2">
                                <p><Trans id="aliens.telegram2.p1"></Trans></p>
                                <p><Trans id="aliens.telegram2.p2"></Trans></p>
                            </div>
                        </div>
                        <Subfooter email="info@aliensapp.com"
                            color={true}
                            onBack={() => {
                                this.load("front")
                            }} isRoot={this.props.isRoot} />
                    </section>
                )
                }
            </I18n>
        );
    }
}

export default Aliens;
